// src/components/Layout.js

import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  useMediaQuery,
  Divider,
  Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { Link, Outlet } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

function Layout() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const drawerWidth = 200; // Adjusted drawer width

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    signOut(auth).catch((error) => {
      console.error('Error during logout:', error);
    });
  };

  const menuItems = [
    { text: 'Dashboard', path: '/dashboard' },
    { text: 'My Lists', path: '/lists' },
    { text: 'Groups', path: '/groups' },
    { text: 'Profile', path: '/profile' },
    { text: 'Logout', action: handleLogout },
  ];

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleDrawer(true)}
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/dashboard"
            sx={{ color: '#fff', textDecoration: 'none' }}
          >
            ListLink
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        open={isMobile ? drawerOpen : true}
        onClose={toggleDrawer(false)}
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <List onClick={isMobile ? toggleDrawer(false) : null}>
          {menuItems.map((item) => (
            <ListItem
              button
              component={item.path ? Link : 'div'}
              to={item.path}
              key={item.text}
              onClick={item.action}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Main Content */}
      <main
        style={{
          flexGrow: 1,
          marginTop: '64px',
          padding: '20px',
          marginLeft: !isMobile ? 50 : 0, // Ensure this matches drawerWidth
        }}
      >
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
