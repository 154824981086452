// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD43W3dnQoQ_zM9bwjAs2cpNbjX5FG2bBA",
  authDomain: "listlink-36a96.firebaseapp.com",
  projectId: "listlink-36a96",
  storageBucket: "listlink-36a96.appspot.com",
  messagingSenderId: "676991688585",
  appId: "1:676991688585:web:e02e74c244d309bd9ecb58",
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
