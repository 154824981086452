// src/components/VerifyEmail.js

import React, { useEffect } from "react";
import { auth } from "../firebase";
import { sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";

function VerifyEmail() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // If email is verified, redirect to dashboard
        navigate("/dashboard");
      }
    });

    // Periodically check if the email is verified
    const interval = setInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        await user.reload(); // Reload user data
        if (user.emailVerified) {
          navigate("/dashboard");
        }
      }
    }, 5000); // Check every 5 seconds

    return () => {
      unsubscribe();
      clearInterval(interval);
    };
  }, [navigate]);

  const resendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      enqueueSnackbar("Verification email resent. Please check your inbox.", {
        variant: "info",
      });
    } catch (error) {
      console.error("Error resending verification email:", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleLogout = async () => {
    await auth.signOut();
    navigate("/");
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Please Verify Your Email Address
          </Typography>
          <Typography variant="body1" paragraph>
            A verification email has been sent to your email address. Please
            check your inbox and click on the verification link to proceed.
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={resendVerificationEmail}
            >
              Resend Email
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default VerifyEmail;
