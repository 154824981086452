// src/components/Chatbot.js

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Fab,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import api from '../api'; // Import your API handler
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function Chatbot({currentListId, onItemAdded}) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false); // State to control chat window visibility
  const theme = useTheme();
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null); // Create a ref for the input field
  const navigate = useNavigate();

  const handleSend = async () => {
    if (input.trim() === '') return;

    const userMessage = {
      role: 'user',
      content: input,
    };

    // Update messages with user's input
    setMessages((prev) => [...prev, userMessage]);
    setInput('');

    try {
      // Prepare conversation history
      const conversation = [...messages, userMessage];

      // Send request to OpenAI API via your backend
      const requestBody = { messages: conversation };
      if (currentListId) {
        requestBody.currentListId = currentListId;
      }

      // Send request to OpenAI API via your backend
      const response = await api.post('/chatbot', requestBody);

      const assistantMessage = {
        role: 'assistant',
        content: response.data.content,
      };

      // Update messages with assistant's response
      setMessages((prev) => [...prev, assistantMessage]);

      // Check if function_response is present and handle accordingly
      if (response.data.function_response) {
        const functionResponse = response.data.function_response;
        const functionName = response.data.function_name;

        if (functionResponse.success) {
          if (functionName === 'create_list') {
            if (functionResponse.data && functionResponse.data._id) {
              const newListId = functionResponse.data._id;

              // Navigate to the list page
              navigate(`/lists/${newListId}`);
            }
          } else if (functionName === 'add_item_to_list') {
            // Notify the parent component that an item was added
            if (onItemAdded) {
              onItemAdded();
            }
          }
          // Handle other functions if needed
        } else {
          // Handle error cases if needed
          console.error('Function execution failed:', functionResponse.message);
        }
      }
    } catch (error) {
      console.error('Error with chatbot:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, there was an error processing your request.',
      };
      setMessages((prev) => [...prev, errorMessage]);
    }
  };

  // Scroll to the bottom when messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Focus input field when chat window opens
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  // Toggle chat window visibility
  const toggleChat = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      {/* Floating Chat Button */}
      {!isOpen && (
        <Fab  
          color="primary"
          onClick={toggleChat}
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: 1000,
          }}
        >
          <ChatIcon />
        </Fab>
      )}

      {/* Chat Window */}
      {isOpen && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            width: '350px',
            height: '500px',
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1000,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {/* Header */}
          <Box
            sx={{
              padding: '8px',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
              Chat Assistant
            </Typography>
            <IconButton
              size="small"
              onClick={toggleChat}
              sx={{ color: theme.palette.primary.contrastText }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Messages Area */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              padding: '8px',
            }}
          >
            {messages.length === 0 ? (
              <Typography variant="body2" color="textSecondary">
                Ask me to perform actions on your lists!
              </Typography>
            ) : (
              <List>
                {messages.map((msg, index) => (
                  <ListItem key={index} sx={{ padding: 0 }}>
                    <Paper
                      sx={{
                        padding: '8px',
                        marginBottom: '8px',
                        maxWidth: '80%',
                        marginLeft: msg.role === 'user' ? 'auto' : '0',
                        backgroundColor:
                          msg.role === 'user'
                            ? theme.palette.primary.light
                            : theme.palette.grey[200],
                      }}
                    >
                      <ListItemText
                        primary={msg.content}
                        primaryTypographyProps={{
                          variant: 'body2',
                          sx: { fontSize: '0.9rem' },
                        }}
                      />
                    </Paper>
                  </ListItem>
                ))}
                <div ref={messagesEndRef} />
              </List>
            )}
          </Box>

          {/* Input Area */}
          <Box
            sx={{
              display: 'flex',
              padding: '8px',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Type a message..."
              fullWidth
              size="small"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSend();
                }
              }}
              InputProps={{
                sx: { fontSize: '0.9rem' },
              }}
              inputRef={inputRef} // Attach the ref to the TextField
            />
            <IconButton color="primary" onClick={handleSend}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Chatbot;
