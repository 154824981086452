// src/components/GroupDetails.js

import React, { useEffect, useState } from 'react';
import api from '../api';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import {
  ExpandMore,
  Save,
  Cancel,
  OpenInNew,
} from '@mui/icons-material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { useSnackbar } from 'notistack';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Linkify from 'linkify-react';

function GroupDetails() {
  const { id } = useParams(); // Group ID from URL
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [listsShared, setListsShared] = useState([]);
  const [sharedLists, setSharedLists] = useState([]);
  const [items, setItems] = useState([]);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false);
  const [emailsToAdd, setEmailsToAdd] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [userLists, setUserLists] = useState([]);

  const [currentUser, loadingAuth, errorAuth] = useAuthState(auth);
  const currentUserId = currentUser?.uid;

  useEffect(() => {
    if (currentUserId) {
      fetchGroupDetails();
      fetchListsSharedWithGroup();
      fetchSharedLists();
      fetchUserLists();
    }
  }, [id, currentUserId]);

  const fetchGroupDetails = async () => {
    try {
      const response = await api.get(`/groups/${id}`);
      setGroup(response.data);
      setEditedName(response.data.name); // Initialize editedName with the group's name
    } catch (error) {
      console.error('Error fetching group details:', error);
      enqueueSnackbar('Failed to fetch group details.', { variant: 'error' });
    }
  };

  const fetchListsSharedWithGroup = async () => {
    try {
      const response = await api.get(`/groups/${id}/lists`);
      setListsShared(response.data);
    } catch (error) {
      console.error('Error fetching lists shared with group:', error);
      enqueueSnackbar('Failed to fetch shared lists.', { variant: 'error' });
    }
  };

  // Fetch shared list items in the group
  const fetchSharedLists = async () => {
    try {
      const response = await api.get(`/groups/${id}/lists/items`);
      const fetchedItems = response.data.items;

      // Map over the items to add purchasedByIds
      const itemsWithAdditionalData = fetchedItems.map((item) => ({
        ...item,
        purchasedByIds: item.purchasedBy?.map((user) => user.firebaseUID) || [], // Extract IDs or return an empty array
      }));


      setSharedLists(response.data.lists);
      setItems(itemsWithAdditionalData);
    } catch (error) {
      console.error('Error fetching shared lists:', error);
      enqueueSnackbar('Failed to fetch shared lists.', { variant: 'error' });
    }
  };

  // Fetch lists owned by or shared directly with the user
  const fetchUserLists = async () => {
    try {
      const response = await api.get('/lists');
      setUserLists(response.data);
    } catch (error) {
      console.error('Error fetching user lists:', error);
      enqueueSnackbar('Failed to fetch user lists.', { variant: 'error' });
    }
  };

  // Purchase an item
  const handlePurchaseItem = async (listId, itemId) => {
    try {
      await api.post(`/lists/${listId}/items/${itemId}/purchase`);
      enqueueSnackbar('Item marked as purchased.', { variant: 'success' });
      fetchSharedLists();
    } catch (error) {
      console.error('Error purchasing item:', error);
      enqueueSnackbar(
        error.response?.data?.message || 'Failed to purchase item.',
        { variant: 'error' }
      );
    }
  };

  // Unpurchase an item
  const handleUnpurchaseItem = async (listId, itemId) => {
    try {
      await api.post(`/lists/${listId}/items/${itemId}/unpurchase`);
      enqueueSnackbar('Purchase undone.', { variant: 'success' });
      fetchSharedLists();
    } catch (error) {
      console.error('Error undoing purchase:', error);
      enqueueSnackbar(
        error.response?.data?.message || 'Failed to undo purchase.',
        { variant: 'error' }
      );
    }
  };

  // Handle name edit toggle
  const handleNameDoubleClick = () => {
    setIsEditingName(true);
  };

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleNameSave = async () => {
    if (editedName.trim() === '') {
      enqueueSnackbar('Group name cannot be empty.', { variant: 'warning' });
      return;
    }
    try {
      const response = await api.put(`/groups/${id}`, { name: editedName });
      setGroup(response.data);
      setIsEditingName(false);
      enqueueSnackbar('Group name updated.', { variant: 'success' });
    } catch (error) {
      console.error('Error updating group name:', error);
      enqueueSnackbar('Failed to update group name.', { variant: 'error' });
    }
  };

  const handleNameCancel = () => {
    setEditedName(group.name);
    setIsEditingName(false);
  };

  const handleAddMembers = async () => {
    const emailList = emailsToAdd
      .split(',')
      .map((email) => email.trim().toLowerCase())
      .filter((email) => email !== '');

    if (emailList.length === 0) {
      enqueueSnackbar('Please enter at least one email.', {
        variant: 'warning',
      });
      return;
    }

    // Simple email format validation
    const invalidEmails = emailList.filter(
      (email) => !/\S+@\S+\.\S+/.test(email)
    );
    if (invalidEmails.length > 0) {
      enqueueSnackbar(`Invalid email(s): ${invalidEmails.join(', ')}`, {
        variant: 'warning',
      });
      return;
    }

    try {
      await api.post(`/groups/${id}/members`, { emails: emailList });
      fetchGroupDetails();
      fetchListsSharedWithGroup();
      setAddMemberDialogOpen(false);
      setEmailsToAdd('');
      enqueueSnackbar('Members added successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error adding members:', error);
      enqueueSnackbar(
        error.response?.data?.message || 'Failed to add members.',
        { variant: 'error' }
      );
    }
  };

  const handleRemoveMember = async (email) => {
    try {
      await api.delete(`/groups/${id}/members`, { data: { email } });
      fetchGroupDetails();
      fetchListsSharedWithGroup();
      enqueueSnackbar('Member removed successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error removing member:', error);
      enqueueSnackbar(
        error.response?.data?.message || 'Failed to remove member.',
        { variant: 'error' }
      );
    }
  };

  // Group items by listId
  const groupedItems = items.reduce((groups, item) => {
    const listId = item.listId;
    if (!groups[listId]) {
      groups[listId] = {
        listId: listId,
        listName: item.listName,
        listOwner: item.listOwner,
        listOwnerEmail: item.listOwnerEmail,
        listOwnerId: item.listOwnerId,
        items: [],
      };
    }
    groups[listId].items.push(item);
    return groups;
  }, {});

  // Create a set of user list IDs for quick lookup
  const userListIds = new Set(userLists.map((list) => list._id));

  return (
    <Box p={3}>
      {group ? (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* Editable Group Name */}
            {isEditingName ? (
              <Box display="flex" alignItems="center">
                <TextField
                  value={editedName}
                  onChange={handleNameChange}
                  variant="outlined"
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleNameSave();
                    }
                  }}
                />
                <IconButton onClick={handleNameSave} color="primary">
                  <Save />
                </IconButton>
                <IconButton onClick={handleNameCancel} color="secondary">
                  <Cancel />
                </IconButton>
              </Box>
            ) : (
              <Typography
                variant="h4"
                gutterBottom
                onDoubleClick={handleNameDoubleClick}
                sx={{ cursor: 'pointer' }}
              >
                {group.name}
              </Typography>
            )}
            <Button
              variant="outlined"
              onClick={() => setAddMemberDialogOpen(true)}
            >
              Share Group
            </Button>
          </Box>

          {/* Filter Options */}
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnlyAvailable}
                  onChange={() => setShowOnlyAvailable(!showOnlyAvailable)}
                />
              }
              label="Show only items that have not been purchased"
            />
          </FormGroup>

          {/* Items List */}
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              Shared Lists
            </Typography>
            {items.length === 0 ? (
              <Typography>No items found.</Typography>
            ) : (
              Object.values(groupedItems).map((group) => (
                <Accordion key={group.listId}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel-${group.listId}-content`}
                    id={`panel-${group.listId}-header`}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography variant="h6">
                        {group.listName} by {group.listOwner || group.listOwnerEmail}
                      </Typography>
                      {/* Icon to navigate to list if user owns it or it's shared with them via email */}
                      {userListIds.has(group.listId) && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion toggle
                            navigate(`/lists/${group.listId}`);
                          }}
                        >
                          <OpenInNew />
                        </IconButton>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {group.items
                      .filter(
                        (item) =>
                          !showOnlyAvailable ||
                          !item.purchasedByIds ||
                          item.purchasedByIds.length === 0
                      )
                      .map((item) => {
                        const isOwner = item.listOwnerId === currentUserId;
                        const hasPurchased = item.purchasedByIds
                          ? item.purchasedByIds.includes(currentUserId)
                          : false;
                        const purchasedBySomeoneElse = item.purchasedByIds?.length > 0 && !hasPurchased;
                        return (
                          <Paper key={item._id} sx={{ p: 1, mb: 1 }}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              {/* Item Details */}
                              <Box flexGrow={1}>
                                <Linkify
                                  options={{
                                    defaultProtocol: 'https',
                                    target: '_blank',
                                    rel: 'noopener noreferrer',
                                  }}
                                >
                                  <Typography variant="body1">

                                    {item.shortDescription}

                                  </Typography>
                                </Linkify>
                              </Box>
                              {/* Conditionally render status chip and buttons for non-owners */}
                              {!isOwner && (
                                <>
                                  {/* Purchase/Unpurchase Button */}
                                  {hasPurchased ? (
                                    <IconButton
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      onClick={() =>
                                        handleUnpurchaseItem(item.listId, item._id)
                                      }
                                    >
                                      <RemoveShoppingCartIcon />
                                    </IconButton>
                                  ) : item.purchasedByIds.length > 0 ? (
                                    <Tooltip title="Purchased by someone else">
                                      <span>
                                        <IconButton
                                          variant="contained"
                                          color="default"
                                          size="small"
                                          disabled
                                        >
                                          <AddShoppingCartIcon />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <IconButton
                                      variant="contained"
                                      color="success"
                                      size="small"
                                      onClick={() =>
                                        handlePurchaseItem(item.listId, item._id)
                                      }
                                    >
                                      <AddShoppingCartIcon />
                                    </IconButton>
                                  )}
                                </>
                              )}

                            </Box>
                          </Paper>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </Box>

          {/* Dialog to add members */}
          <Dialog
            open={addMemberDialogOpen}
            onClose={() => setAddMemberDialogOpen(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Group Members</DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1">Members:</Typography>
              <Box mt={2} display="flex" flexWrap="wrap">
                {group.members.map((member) => (
                  <Chip
                    key={member._id}
                    label={member.email}
                    onDelete={() => handleRemoveMember(member.email)}
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
              <DialogTitle>Add Members to {group.name}</DialogTitle>
              <TextField
                label="Emails to Add"
                value={emailsToAdd}
                onChange={(e) => setEmailsToAdd(e.target.value)}
                variant="outlined"
                fullWidth
                placeholder="Enter emails separated by commas"
                multiline
                minRows={2}
                helperText="Separate multiple emails with commas. People you add to this group can view
                    all lists shared with the group and purchase items off the lists without the list owner being able to see."
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setAddMemberDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleAddMembers} variant="contained">
                Add Members
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Typography>Loading group details...</Typography>
      )}
    </Box>
  );
}

export default GroupDetails;
