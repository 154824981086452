// src/components/HomePage.js

import React from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Import images or illustrations
import heroImage from '../assets/hero-image.webp';
import feature1Image from '../assets/feature1.webp';
import feature2Image from '../assets/feature2.webp';

const useStyles = makeStyles((theme) => ({
  heroSection: {
    padding: theme.spacing(8, 0),
    backgroundColor: '#f5f5f5',
  },
  heroContent: {
    maxWidth: 1200,
    margin: '0 auto',
  },
  heroText: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(4),
    },
  },
  heroImage: {
    width: '100%',
    maxWidth: 500,
  },
  featureSection: {
    padding: theme.spacing(8, 0),
  },
  featureImage: {
    width: '100%',
    height: 'auto',
    maxHeight: 300, // Set a max height to standardize
    objectFit: 'contain',
  },
  featureCard: {
    textAlign: 'center',
    boxShadow: 'none',
  },
  ctaSection: {
    padding: theme.spacing(8, 0),
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

function HomePage() {
  const classes = useStyles();

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebApplication",
    "name": "ListLink",
    "url": "https://list-link.com",
    "description": "Collaborative to-do and gift list app. Create shared to-do lists, gift lists, and grocery lists effortlessly.",
    "applicationCategory": "Productivity",
    "operatingSystem": "All",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    }
  };

  return (
    <div>
      <Helmet>
        <title>ListLink - Collaborative To-Do and Gift List App</title>
        <meta
          name="description"
          content="Create and share to-do lists, gift lists, and grocery lists effortlessly with ListLink. Collaborate in real-time and manage tasks efficiently."
        />
        <meta
          name="keywords"
          content="to-do list app, gift list app, collaborative task management, shared to-do lists, group gift lists, grocery list app"
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Text Content */}
            <Grid item xs={12} md={6} className={classes.heroText}>
              <Typography variant="h2" component="h1" gutterBottom>
                Collaborate on Shared To-Do and Gift Lists with ListLink
              </Typography>
              <Typography variant="h5" color="textSecondary" paragraph>
                Create and manage shared to-do lists, gift lists, and grocery lists effortlessly. Collaborate in real-time and stay organized.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/login"
                sx={{ mr: 2 }}
              >
                Get Started
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                component={Link}
                to="/learn-more"
                sx={{ mt: { xs: 2, md: 0 } }}
              >
                Learn More
              </Button>
            </Grid>
            {/* Hero Image */}
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <img
                  src={heroImage}
                  alt="ListLink - Collaborative To-Do and Gift List App"
                  className={classes.heroImage}
                  loading="lazy"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box className={classes.featureSection}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom>
            Key Features
          </Typography>
          <Grid container spacing={4} alignItems="flex-start">
            {/* Feature 1 */}
            <Grid item xs={12} md={6}>
              <Box className={classes.featureCard}>
                <img
                  src={feature1Image}
                  alt="Create and Share To-Do Lists with ListLink"
                  className={classes.featureImage}
                  loading="lazy"
                />
                <Typography variant="h5" gutterBottom>
                  Create and Share To-Do Lists
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Our to-do list app lets you create and share tasks with anyone. Collaborate in real-time on shared to-do lists and manage tasks efficiently.
                </Typography>
              </Box>
            </Grid>
            {/* Feature 2 */}
            <Grid item xs={12} md={6}>
              <Box className={classes.featureCard}>
                <img
                  src={feature2Image}
                  alt="Organize Anonymous Group Gift Lists on ListLink"
                  className={classes.featureImage}
                  loading="lazy"
                />
                <Typography variant="h5" gutterBottom>
                  Organize Anonymous Group Gift Lists
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Set up group gift lists where participants can purchase items anonymously. Perfect for surprises and special occasions.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call-to-Action Section */}
      <Box className={classes.ctaSection}>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            Join ListLink Today
          </Typography>
          <Typography variant="h6" gutterBottom>
            Start collaborating on shared to-do lists, gift lists, and grocery lists. Manage tasks effortlessly.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/login"
          >
            Sign Up Now
          </Button>
        </Container>
      </Box>
    </div>
  );
}

export default HomePage;