// src/App.js

import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { initGA, logPageView } from './analytics';
import Layout from './components/Layout';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import RequireAuth from './components/RequireAuth';
import ListView from './components/ListView';
import ListDetail from './components/ListDetail';
import GroupView from './components/GroupView';
import GroupDetails from './components/GroupDetails';
import Profile from './components/Profile';
import VerifyEmail from './components/VerifyEmail';
import HomePage from './components/HomePage';
import LearnMorePage from './components/LearnMorePage';
import PublicLayout from './components/PublicLayout';
import Chatbot from './components/Chatbot';


function App() {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicLayout />}>
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/learn-more" element={<LearnMorePage />} />
        </Route>

        {/* Private Routes */}
        {/* Protected Routes */}
        <Route element={<RequireAuth><Layout /></RequireAuth> }        >
          <Route path="/dashboard" element={<><Dashboard /><Chatbot /></>} />
          <Route path="/lists" element={<><ListView /><Chatbot /></>} />
          <Route path="/lists/:id" element={<ListDetail />} />
          <Route path="/groups" element={<><GroupView /><Chatbot /></>} />
          <Route path="/groups/:id" element={<><GroupDetails /><Chatbot /></>} />
          <Route path="/profile" element={<><Profile /><Chatbot /></>} />
        </Route>
        
      </Routes>
    </Router>
  );
}

function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
}

export default App;
