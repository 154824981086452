// src/components/PublicLayout.js

import React from 'react';
import { AppBar, Toolbar, Button, CssBaseline, Box } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import logo from '../assets/company-logo.webp'; // Import your SVG logo

function PublicLayout() {
  return (
    <div>
      <CssBaseline />
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Space between logo and buttons
            alignItems: 'center',
            px: 2, // Add padding to the toolbar for a clean look
          }}
        >
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <img src={logo} alt="Company Logo" style={{ height: '40px' }} />
            </Link>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: 1, // Add spacing between buttons
              flexGrow: 0, // Prevent buttons from growing unnecessarily
            }}
          >
            <Button color="inherit" component={Link} to="/learn-more">
              Learn More
            </Button>
            <Button color="inherit" component={Link} to="/register">
              Sign Up
            </Button>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main content */}
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default PublicLayout;
