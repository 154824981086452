// src/components/Login.js

import React, { useState } from 'react';
import { auth } from '../firebase';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link as MuiLink,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  // State variables for login
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // State variables for error handling
  const [error, setError] = useState('');

  // State variables for password reset
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState('');
  const [resetSuccess, setResetSuccess] = useState('');
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);

  const navigate = useNavigate();

  // Handle login form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    }
  };

  // Open password reset dialog
  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setIsResetDialogOpen(true);
    setResetEmail('');
    setResetError('');
    setResetSuccess('');
  };

  // Send password reset email
  const handleSendResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess('Password reset email sent. Please check your inbox.');
      setResetError('');
    } catch (error) {
      setResetError(error.message);
      setResetSuccess('');
    }
  };

  // Close password reset dialog
  const handleCloseResetDialog = () => {
    setIsResetDialogOpen(false);
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8 }}>
        <Typography variant="h4" component="h1" align="center">
          Login
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            type="password"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </form>

        {/* Forgot Password Link */}
        <Typography align="center" sx={{ mt: 2 }}>
          <MuiLink href="#" onClick={handleForgotPasswordClick}>
            Forgot your password?
          </MuiLink>
        </Typography>

        {/* Password Reset Dialog */}
        <Dialog
          open={isResetDialogOpen}
          onClose={handleCloseResetDialog}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            {resetSuccess ? (
              <Alert severity="success">{resetSuccess}</Alert>
            ) : (
              <>
                {resetError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {resetError}
                  </Alert>
                )}
                <TextField
                  autoFocus
                  margin="dense"
                  label="Email Address"
                  type="email"
                  fullWidth
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseResetDialog}>Close</Button>
            {!resetSuccess && (
              <Button onClick={handleSendResetEmail} color="primary">
                Send Reset Email
              </Button>
            )}
          </DialogActions>
        </Dialog>

        {/* Register Link */}
        <Typography align="center" sx={{ mt: 2 }}>
          Don't have an account?{' '}
          <Link to="/register" style={{ textDecoration: 'none' }}>
            Sign Up
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;
