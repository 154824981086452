// src/components/Profile.js

import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import api from '../api';
import { useSnackbar } from 'notistack';
import {
  updateProfile,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

function Profile() {
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [originalEmail, setOriginalEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const { enqueueSnackbar } = useSnackbar();
  
    useEffect(() => {
      const user = auth.currentUser;
      if (user) {
        setDisplayName(user.displayName || '');
        setEmail(user.email || '');
        setOriginalEmail(user.email || '');
      }
      // Fetch current user data
      const fetchUser = async () => {
        try {
          const response = await api.get('/users/profile');
          setName(response.data.name);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          enqueueSnackbar('Failed to load profile.', { variant: 'error' });
        }
      };
      fetchUser();
    }, [enqueueSnackbar]);
  
    const handleSave = async () => {
      setError('');
      setSuccess('');
      const user = auth.currentUser;
  
      try {
        if (user) {
          // Update display name
          if (user.displayName !== displayName) {
            const name = displayName
            const response = await api.put('/users/profile', { name });
            await updateProfile(user, { displayName });
          }
  
          // Update email
          if (user.email !== email) {
            // Re-authenticate user before updating email
            setPasswordDialogOpen(true);
          } else {
            setSuccess('Profile updated successfully.');
          }
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        setError(error.message);
      }
    };
  
    const handleEmailUpdate = async () => {
      const user = auth.currentUser;
      if (user && currentPassword) {
        try {
          const credential = EmailAuthProvider.credential(originalEmail, currentPassword);
          await reauthenticateWithCredential(user, credential);
          await updateEmail(user, email);
          setSuccess('Profile updated successfully.');
          setPasswordDialogOpen(false);
          setCurrentPassword('');
        } catch (error) {
          console.error('Error updating email:', error);
          setError(error.message);
        }
      } else {
        setError('Please enter your current password.');
      }
    };
  
    const handlePasswordReset = async () => {
      try {
        await sendPasswordResetEmail(auth, email);
        setSuccess('Password reset email sent.');
      } catch (error) {
        console.error('Error sending password reset email:', error);
        setError(error.message);
      }
    };
  
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="Display Name"
            variant="outlined"
            margin="normal"
            fullWidth
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSave}>
            Save Changes
          </Button>
          <Button variant="outlined" color="secondary" sx={{ mt: 2, ml: 2 }} onClick={handlePasswordReset}>
            Change Password
          </Button>
        </Box>
  
        {/* Dialog for re-authentication */}
        <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
          <DialogTitle>Confirm Password</DialogTitle>
          <DialogContent>
            <Typography>Please enter your current password to update your email.</Typography>
            <TextField
              label="Current Password"
              type="password"
              fullWidth
              margin="normal"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPasswordDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleEmailUpdate}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
  
  export default Profile;
  