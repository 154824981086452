// src/components/RequireAuth.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function RequireAuth({ children }) {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    // Show a loading indicator
    return <div>Loading...</div>;
  }

  if (!user) {
    // If not logged in, redirect to login page
    return <Navigate to="/" />;
  }

  if (!user.emailVerified) {
    // If email is not verified, redirect to verification info page
    return <Navigate to="/verify-email" />;
  }

  return children;
}

export default RequireAuth;
