// src/components/GroupView.js

import React, { useEffect, useState } from 'react';
import api from '../api';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Alert,
  Paper,
  Box,
  Chip,
  CircularProgress,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


function GroupView() {
  const [groups, setGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGroups = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await api.get('/groups');
      const data = response.data;

      if (response.status === 204 || !Array.isArray(data)) {
        setGroups([]);
      } else {
        setGroups(data);
      }
    } catch (error) {
      console.error('Error fetching groups:', error);
      setError('Failed to load groups. Please try again.');
      setGroups([]);
    } finally {
      setLoading(false);
    }
  };

  const handleGroupClick = (groupId) => {
    navigate(`/groups/${groupId}`);
  };

  const handleCreate = async () => {
    try {
      await api.post('/groups', { name: newGroupName });
      setNewGroupName('');
      setOpen(false);
      fetchGroups();
    } catch (error) {
      console.error('Error creating group:', error);
      setError('Failed to create group. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/groups/${id}`);
      fetchGroups();
    } catch (error) {
      console.error('Error deleting group:', error);
      setError('Failed to delete group. Please try again.');
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        My Groups
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
        sx={{ mt: 2, mb: 2 }}
      >
        Create New Group
      </Button>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {Array.isArray(groups) && groups.length > 0 ? (
            <List>
              {groups.map((group) => (
                <Paper
                  key={group._id}
                  sx={{ p: 1, mb: 1, cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5' } }}
                  onClick={() => handleGroupClick(group._id)} // Make group clickable
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{group.name}</Typography>
                    <Box display="flex" gap={1}>
                      {group.members.length > 0 && (
                        <Box display="flex" alignItems="center">
                          {group.members.slice(0, 2).map((member) => (
                            <Chip
                              key={member._id}
                              label={member.email}
                              sx={{ mr: 1, mb: 1 }}
                            />
                          ))}
                          {group.members.length > 2 && (
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              +{group.members.length - 2} more
                            </Typography>
                          )}
                        </Box>
                      )}
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDelete(group._id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper>
              ))}
            </List>
          ) : (
            <Typography>No groups found.</Typography>
          )}
        </>
      )}

      {/* Dialog for creating a new group */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            value={newGroupName}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCreate();
              }
            }}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GroupView;
