// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import api from '../api';
import {
  Typography,
  Box,
  Paper,
  Chip,
  Button,
  Dialog,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [groups, setGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [listOpen, setListOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newListName, setNewListName] = useState('');
  const [lists, setLists] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMyGroups();
    fetchMyLists();
  }, []);

  const fetchMyGroups = async () => {
    try {
      const response = await api.get('/groups');
      setGroups(response.data);
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };

  const fetchMyLists = async () => {
    try {
      const response = await api.get('/lists?includeItems=false');
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
    }
  };

  const handleNavigateToGroups = () => {
    navigate('/groups');
  };

  const handleNavigateToLists = () => {
    navigate('/lists');
  };

  const handleGroupClick = (groupId) => {
    navigate(`/groups/${groupId}`);
  };

  const handleListClick = (listId) => {
    navigate(`/lists/${listId}`);
  };

  const handleGroupCreate = async () => {
    try {
      await api.post('/groups', { name: newGroupName });
      setNewGroupName('');
      setOpen(false);
      fetchMyGroups();
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const handleListCreate = async () => {
    try {
      const response = await api.post('/lists', { name: newListName });
      const newListId = response.data._id;

      setNewListName('');
      setListOpen(false);
      fetchMyLists();
      // Navigate to the new list's page
      navigate(`/lists/${newListId}`);
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Welcome to ListLink!
      </Typography>

      {/* My Lists Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Tooltip
          title={
            'Lists allow you to add and track items. They can be shared with individuals (they can edit and manage items) or groups (members can view and purchase items but cannot make changes).'
          }
          arrow
        >
          <Typography variant="h5" sx={{ cursor: 'help' }}>
            My Lists
          </Typography>
        </Tooltip>
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setListOpen(true)}
            sx={{ mt: 2, mb: 1 }}
          >
            Create New List
          </Button>
          <Button variant="contained" onClick={handleNavigateToLists} sx={{ mt: 2, mb: 1 }}>
            Manage Lists
          </Button>
        </Box>
      </Box>

      {lists.length === 0 ? (
        <Typography>No lists found. Create one on the lists page.</Typography>
      ) : (
        lists.map((list) => (
          <Paper
            key={list._id}
            sx={{
              p: 1,
              mb: 1,
              mt: 1,
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#f5f5f5' },
            }}
            onClick={() => handleListClick(list._id)}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{list.name}</Typography>
            </Box>
          </Paper>
        ))
      )}

      {/* My Groups Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Tooltip
          title={
            'Groups let you share lists with friends, allowing them to view all lists shared with the group and anonymously purchase items.'
          }
          arrow
        >
          <Typography variant="h5" sx={{ cursor: 'help' }}>
            My Groups
          </Typography>
        </Tooltip>
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setOpen(true)}
            sx={{ mt: 2, mb: 1 }}
          >
            Create New Group
          </Button>
          <Button variant="contained" onClick={handleNavigateToGroups} sx={{ mt: 2, mb: 1 }}>
            Manage Groups
          </Button>
        </Box>
      </Box>

      {groups.length === 0 ? (
        <Typography>No groups found. Create one on the Groups page.</Typography>
      ) : (
        groups.map((group) => (
          <Paper
            key={group._id}
            sx={{
              p: 1,
              mb: 1,
              mt: 1,
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#f5f5f5' },
            }}
            onClick={() => handleGroupClick(group._id)}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{group.name}</Typography>
              <Box>
                {group.members.map((member) => (
                  <Chip key={member._id} label={member.email} sx={{ mr: 1, mb: 1 }} />
                ))}
              </Box>
            </Box>
          </Paper>
        ))
      )}

      {/* Dialog for creating a new group */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            value={newGroupName}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleGroupCreate();
              }
            }}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleGroupCreate}>Create</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for creating a new list */}
      <Dialog open={listOpen} onClose={() => setListOpen(false)}>
        <DialogTitle>Create New List</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="List Name"
            type="text"
            fullWidth
            value={newListName}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleListCreate();
              }
            }}
            onChange={(e) => setNewListName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setListOpen(false)}>Cancel</Button>
          <Button onClick={handleListCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;
